
import React from 'react'
import './index.css'

const  ToneLoader =() => {
 
 
     return (
       <div  className='tone-loader'>
             <img src="/assets/tone_logo.gif" alt="toneLoader-gif"  />
        </div>
  )
}

export default ToneLoader;
