

export const movieNewsCardData={
      LatestNews:[
          {
            id:1,
            headerText:"ఇన్నాళ్లకు రాజమౌళిని భయపెట్టే డైరెక్టర్ వచ్చాడు!",
            cardText:"ప్రస్తుతం ఇండియాలో టాప్ డైరెక్టర్ ఎవరంటే ముక్తకంఠంతో అందరూ చెప్పే పేరు దర్శధీరుడు ఎస్.ఎస్. రాజమౌళి (SS Rajamouli)",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20director%20nag%20ashwin.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },

          {
            id:2,
            headerText:"'కల్కి 2898 AD' ఓటీటీ అప్డేట్.. అసలు జనాలు చూస్తారా..?",
        cardText:"ప్రస్తుతం ఎక్కడ చూసినా 'కల్కి 2898 AD' (Kalki 2898 AD) మేనియానే కనిపిస్తోంది. ఆన్ లైన్, ఆఫ్ లైన్ తేడా లేక",
        imgUrl:"https://teluguone.com/teluguoneUserFiles/img/prabhas%20kalki%20ott%20update.webp",    
        newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },
          {
            id:3,
            headerText:"కల్కి 2898 AD.. అదొక్కటే మైనస్...",
            cardText:"సినీ ప్రియులంతా ఎంతగానో ఎదురుచూసిన 'కల్కి 2898 AD' (Kalki 2898 AD) సినిమా భారీ అంచనాల నడుమ థియేటర్లలో అడు",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20minus%20points.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          }
      ],
      FeaturedNews:[
        {
            id:1,
            headerText:"ఇన్నాళ్లకు రాజమౌళిని భయపెట్టే డైరెక్టర్ వచ్చాడు!",
            cardText:"ప్రస్తుతం ఇండియాలో టాప్ డైరెక్టర్ ఎవరంటే ముక్తకంఠంతో అందరూ చెప్పే పేరు దర్శధీరుడు ఎస్.ఎస్. రాజమౌళి (SS Rajamouli)",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20director%20nag%20ashwin.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },

        {
          id:2,
          headerText:"'కల్కి 2898 AD' ఓటీటీ అప్డేట్.. అసలు జనాలు చూస్తారా..?",
        cardText:"ప్రస్తుతం ఎక్కడ చూసినా 'కల్కి 2898 AD' (Kalki 2898 AD) మేనియానే కనిపిస్తోంది. ఆన్ లైన్, ఆఫ్ లైన్ తేడా లేక",
        imgUrl:"https://teluguone.com/teluguoneUserFiles/img/prabhas%20kalki%20ott%20update.webp",  
        newsPageLink:"",
          fbLink:"",
          twitterLink:"",
          moreLink:"",
        },
        {
            id:3,
            headerText:"కల్కి 2898 AD.. అదొక్కటే మైనస్...",
            cardText:"సినీ ప్రియులంతా ఎంతగానో ఎదురుచూసిన 'కల్కి 2898 AD' (Kalki 2898 AD) సినిమా భారీ అంచనాల నడుమ థియేటర్లలో అడు",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20minus%20points.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          }
    ],
    BollyWoodNews:[
        {
            id:1,
            headerText:"ఇన్నాళ్లకు రాజమౌళిని భయపెట్టే డైరెక్టర్ వచ్చాడు!",
            cardText:"ప్రస్తుతం ఇండియాలో టాప్ డైరెక్టర్ ఎవరంటే ముక్తకంఠంతో అందరూ చెప్పే పేరు దర్శధీరుడు ఎస్.ఎస్. రాజమౌళి (SS Rajamouli)",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20director%20nag%20ashwin.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },

        {
            id:2,
            headerText:"'కల్కి 2898 AD' ఓటీటీ అప్డేట్.. అసలు జనాలు చూస్తారా..?",
          cardText:"ప్రస్తుతం ఎక్కడ చూసినా 'కల్కి 2898 AD' (Kalki 2898 AD) మేనియానే కనిపిస్తోంది. ఆన్ లైన్, ఆఫ్ లైన్ తేడా లేక",
          imgUrl:"https://teluguone.com/teluguoneUserFiles/img/prabhas%20kalki%20ott%20update.webp",  
          newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },
          {
            id:3,
            headerText:"కల్కి 2898 AD.. అదొక్కటే మైనస్...",
            cardText:"సినీ ప్రియులంతా ఎంతగానో ఎదురుచూసిన 'కల్కి 2898 AD' (Kalki 2898 AD) సినిమా భారీ అంచనాల నడుమ థియేటర్లలో అడు",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20minus%20points.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          }
    ],
    TvNews:[
        {
            id:1,
            headerText:"ఇన్నాళ్లకు రాజమౌళిని భయపెట్టే డైరెక్టర్ వచ్చాడు!",
            cardText:"ప్రస్తుతం ఇండియాలో టాప్ డైరెక్టర్ ఎవరంటే ముక్తకంఠంతో అందరూ చెప్పే పేరు దర్శధీరుడు ఎస్.ఎస్. రాజమౌళి (SS Rajamouli)",
            imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20director%20nag%20ashwin.webp",
            newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },

        {
            id:2,
            headerText:"'కల్కి 2898 AD' ఓటీటీ అప్డేట్.. అసలు జనాలు చూస్తారా..?",
          cardText:"ప్రస్తుతం ఎక్కడ చూసినా 'కల్కి 2898 AD' (Kalki 2898 AD) మేనియానే కనిపిస్తోంది. ఆన్ లైన్, ఆఫ్ లైన్ తేడా లేక",
          imgUrl:"https://teluguone.com/teluguoneUserFiles/img/prabhas%20kalki%20ott%20update.webp",  
          newsPageLink:"",
            fbLink:"",
            twitterLink:"",
            moreLink:"",
          },
        {
          id:3,
          headerText:"కల్కి 2898 AD.. అదొక్కటే మైనస్...",
          cardText:"సినీ ప్రియులంతా ఎంతగానో ఎదురుచూసిన 'కల్కి 2898 AD' (Kalki 2898 AD) సినిమా భారీ అంచనాల నడుమ థియేటర్లలో అడు",
          imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20minus%20points.webp",
          newsPageLink:"",
          fbLink:"",
          twitterLink:"",
          moreLink:"",
        }
    ],
    Gossips:[
      {
          id:1,
          headerText:"ఇన్నాళ్లకు రాజమౌళిని భయపెట్టే డైరెక్టర్ వచ్చాడు!",
          cardText:"ప్రస్తుతం ఇండియాలో టాప్ డైరెక్టర్ ఎవరంటే ముక్తకంఠంతో అందరూ చెప్పే పేరు దర్శధీరుడు ఎస్.ఎస్. రాజమౌళి (SS Rajamouli)",
          imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20director%20nag%20ashwin.webp",
          newsPageLink:"",
          fbLink:"",
          twitterLink:"",
          moreLink:"",
        },

      {
          id:2,
          headerText:"'కల్కి 2898 AD' ఓటీటీ అప్డేట్.. అసలు జనాలు చూస్తారా..?",
        cardText:"ప్రస్తుతం ఎక్కడ చూసినా 'కల్కి 2898 AD' (Kalki 2898 AD) మేనియానే కనిపిస్తోంది. ఆన్ లైన్, ఆఫ్ లైన్ తేడా లేక",
        imgUrl:"https://teluguone.com/teluguoneUserFiles/img/prabhas%20kalki%20ott%20update.webp",  
        newsPageLink:"",
          fbLink:"",
          twitterLink:"",
          moreLink:"",
        },
      {
        id:3,
        headerText:"కల్కి 2898 AD.. అదొక్కటే మైనస్...",
        cardText:"సినీ ప్రియులంతా ఎంతగానో ఎదురుచూసిన 'కల్కి 2898 AD' (Kalki 2898 AD) సినిమా భారీ అంచనాల నడుమ థియేటర్లలో అడు",
        imgUrl:"https://teluguone.com/teluguoneUserFiles/img/kalki%202898%20ad%20minus%20points.webp",
        newsPageLink:"",
        fbLink:"",
        twitterLink:"",
        moreLink:"",
      }
  ],
}


